import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "../src/scss/main.css";
import "../src/scss/animate.min.css";
import { Web3Provider } from "@ethersproject/providers";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import { NetworkContextName } from "src/constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { CookiesProvider } from "react-cookie";
import CookieConsent from "react-cookie-consent";
import { Typography ,Box } from "@material-ui/core";
const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 15000;
  return library;
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Web3ProviderNetwork getLibrary={getLibrary}>
     
        

        <CookieConsent
          location="bottom"
          buttonText="Accept All"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#061121" }}
          buttonStyle={{
            background: "linear-gradient(180deg, #51D2FC 0%, #0099DC 100%)",
            borderRadius: "10px",
            color: "#fff",
            fontSize: "15px",
            lineHeight: " 20px",
            border: "1px solid #43c8f69c",
            height: "40px",
            whiteSpace: "pre",
            minWidth:"100px"
          }}
          expires={150}
        >
          <Box>
          </Box>

          <Typography variant="body1" style={{ fontSize: "12px" }}>
          This website uses cookies to ensure you get the best experience. By using our site, you agree to our use of cookies and our terms of use.
          </Typography>
        </CookieConsent>
        
        <App />
      
      <ToastContainer />
    </Web3ProviderNetwork>
  </Web3ReactProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
