import React, { useContext, useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
  Button,
  Divider,
  InputBase,
  FormHelperText,
  IconButton,
  Link,
} from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";
import Logo from "./../../component/Logo";
// import { UserContext } from "src/context/User";
import { useWeb3React } from "@web3-react/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import * as yep from "yup";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ApiConfig from "src/config/APICongig";
// import Apiconfigs from "src/ApiConfig/ApiConfig";
import { toast } from "react-toastify";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF, FaInstagram, FaMediumM } from "react-icons/fa";
import { FiSend, FiTwitter, FiYoutube } from "react-icons/fi";
import {
  AiOutlineReddit,
  AiFillYoutube,
  AiOutlineMedium,
} from "react-icons/ai";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import CookieModal from "src/component/CookieModal";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    zIndex: "9",
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      zIndex: "9",
    },
    [theme.breakpoints.up("md")]: {
      position: "relative",
      zIndex: "9",
    },
    "& h1": {
      fontWeight: "600",
      fontSize: "31px",
      [theme.breakpoints.down("md")]: {
        fontSize: "33px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "23px",
      },
    },
    "& h6": {
      fontWeight: "400",
      fontSize: "14px",
      color: "#B7B7B7",
    },
    "& h2": {
      fontWeight: "700",
      fontSize: "25px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "17px !important",
      },
      "& span": {
        color: "#25b3eb",
        background: "linear-gradient(180deg, #51D2FC 0%, #0099DC 100%)",
        marginLeft: "-11px",
        padding: "1px",
      },
    },
  },
  baseSection: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .baseSection": {
      width: "45px",
    },
    "& p": {
      color: "#fff",
      fontSize: "13px",
    },
    "@media(max-width:565px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  privacy: {
    flexWrap: "wrap",
    "& p": {
      color: "rgba(255, 255, 255, 0.6)",
      fontWeight: "400",
      fontSize: "14px",
    },
    display: "flex",
    "@media(max-width:565px)": {
      marginTop: "1.5rem",
    },
  },
  listitem: {
    textDecoration: "none",
    fontFamily: "'Acumin Pro', sans-serif",
    fontStyle: "normal",
    paddingTop: "16px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "16px",
    color: "  rgba(255, 255, 255, 0.6)",
    cursor: "pointer",
    background: "none",
    cursor: "pointer",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    "@media(max-width:599px)": {
      fontSize: "12px",
    },
  },
  subpart: {
    backgroundColor: "#040C18",
    padding: "50px 0px 20px",
    position: "relative",
    // backgroundImage: "url(images/darkwater1.png)",
    // padding: "200px 10px 16px 10px",
    "& .imagebox": {
      position: "absolute",
      zIndex: "0",
      right: "20px",
      [theme.breakpoints.down("xs")]: {
        bottom: "20px",
      },
    },
    "& h6": {
      fontSize: "15px",
      color: "#FFFFFF",
      fontWeight: "300",
      lineHeight: "28px",
      width: "100%",
      maxWidth: "448px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        maxWidth: "100%",
      },
      "@media(max-width:599px)": {
        fontSize: "14px",
        width: "100%",
        maxWidth: "100%",
        marginBottom: "10px",
        lineHeight: "24px",
      },
    },
    "@media(max-width:599px)": {
      padding: "20px 10px 24px 10px",
    },
    "& .MuiDivider-root": {
      backgroundColor: "rgb(244 244 244 / 98%)",
    },
  },
  listbox: {
    paddingTop: "8px",
    background: "none",
    border: "none",
    margin: "0px",
    paddingLeft: "4px",
    "& .MuiList-padding": {
      paddingLeft: "4px",
    },
  },
  logotext: {
    "& h1": {
      fontWeight: "700",
      color: "#EAB73B",
    },
  },
  decoration: {
    textDecoration: "none",
    cursor: "pointer",
  },
  textFild: {
    position: "relative",
    "& .MuiInputBase-root": {
      border: "0px solid transparent",
    },
    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      background: "linear-gradient(180deg, #51D2FC 0%, #0099DC 100%)",
      minWidth: "140px",
      fontSize: "16px",
      fontWeight: "400",
      color: "#fff",
      borderRadius: "10px",
      "&:hover": {
        background: "linear-gradient(180deg, #51D2FC 0%, #0099DC 100%)",
        color: "#000",
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "100px",
      },
    },
    iconhandle: {
      "& button": {
        "& a": {
          "& svg": {
            color: "#fff",
          },
        },
      },
    },
  },
  signbox: {
    // "& p": {
    //   fontFamily: "'Poppins'",
    //   fontStyle: "normal",
    //   fontWeight: "400",
    //   fontSize: "12px",
    //   lineHeight: "22px",
    //   color: "#FFFFFF",
    // },
  },
  inputfield: {
    "& .inputfield": {
      width: "100%",
      borderRadius: "10px",
      padding: "13px",
      backgroundColor: "#fff",
    },
    "& input": {
      color: "#000 !important",
      fontSize: "12px",
    },
  },
  sociallinknbox: {
    marginLeft: "-2px !important",
    paddingTop: "18px",
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  // const user = useContext(UserContext);
  const { account } = useWeb3React();
  const history = useHistory();
  const Tokencheck = window.sessionStorage.getItem("token");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);

    try {
      const response = await axios({
        method: "POST",
        url: ApiConfig.subscribeNow,
        data: {
          userEmail: values.email,
          newsLettarStatus: "ACTIVE",
          subject: "string",
          title: "string",
        },
      });
      if (response.data.status === 200) {
        toast.success("You have subscribed newsletter");
        setIsLoading(false);
      } else if (response.data.status === 205) {
        toast.warn("You have already subscribed");
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Box className={classes.footerSection}>
        <Box>
          <Box className={classes.subpart}>
            <img src="images/footerback.png" className="imagebox" />
            <Container>
              <Box className={classes.signbox}>
                <Grid container style={{ color: "white" }} spacing={5}>
                  <Grid item xs={6} sm={6} md={4} lg={4}>
                    <Typography variant="h2">
                      <span>.</span>
                      &nbsp; Menu
                    </Typography>
                    <List className={classes.listbox}>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/",
                          });
                        }}
                      >
                        Home
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/aboutus",
                          });
                        }}
                      >
                        About Us
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/our-team",
                          });
                        }}
                      >
                        Our Team
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/contactUs",
                          });
                        }}
                      >
                        Contact Us
                      </ListItem>{" "}
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/merchandise",
                          });
                        }}
                      >
                        Merchandise
                      </ListItem>{" "}
                      <ListItem
                        className={classes.listitem}
                        // onClick={() => {
                        //   history.push({
                        //     pathname: "/merchandise",
                        //   });
                        // }}
                      >
                        White Paper
                      </ListItem>{" "}
                    </List>{" "}
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={4}>
                    <Typography variant="h2">
                      <span>.</span>
                      &nbsp; Company
                    </Typography>
                    <List className={classes.listbox}>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/help-planet",
                          });
                        }}
                      >
                        Help the planet
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/carrers",
                          });
                        }}
                      >
                        Careers
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/where-buy",
                          });
                        }}
                      >
                        Where to buy
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/return-bottle",
                          });
                        }}
                      >
                        Bottle return
                      </ListItem>{" "}
                      {/* <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/merchandise",
                          });
                        }}
                      >
                       Our Impact
                      </ListItem>{" "} */}
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/partner-with-us",
                          });
                        }}
                      >
                        Partner with us
                      </ListItem>{" "}
                    </List>{" "}
                  </Grid>
                  <Grid item xs={12} sm={8} md={4} lg={4}>
                    <Formik
                      onSubmit={(values) => handleFormSubmit(values)}
                      initialValues={{
                        email: "",
                      }}
                      initialStatus={{
                        success: false,
                        successMsg: "",
                      }}
                      validationSchema={yep.object().shape({
                        email: yep
                          .string()
                          .required("Please enter valid email address.")
                          .matches(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            "Please enter a valid email address"
                          ),
                      })}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                        setFieldValue,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Typography variant="h2" style={{ fontSize: "17px" }}>
                            <span>.</span> &nbsp;Subscribe to Newsletter
                          </Typography>
                          <Box pt={2} pb={2}>
                            <Box className={classes.textFild}>
                              <FormControl
                                fullWidth
                                className={classes.inputfield}
                              >
                                <InputBase
                                  className="inputfield"
                                  id="outlined-basic"
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Your e-mail address"
                                  name="email"
                                  value={values.email}
                                  error={Boolean(touched.email && errors.email)}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  disabled={isLoading}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Button
                                        type="submit"
                                        disabled={isLoading}
                                      >
                                        Subscribe{" "}
                                        {isLoading && (
                                          <ButtonCircularProgress />
                                        )}
                                      </Button>
                                    </InputAdornment>
                                  }
                                />
                                <FormHelperText error>
                                  {touched.email && errors.email}
                                </FormHelperText>
                              </FormControl>
                              {/* <Button type="submit">Subscribe</Button> */}
                            </Box>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                    <Typography variant="h2">
                      <span>.</span>
                      &nbsp; Contact Us
                    </Typography>
                    <List className={classes.listbox}>
                      <Typography className={classes.listitem}>
                        {/* 25 W 51st St, New York, NY 10019 */}
                        34-30 Steinway Street, Astoria NY 11101
                      </Typography>
                      <Typography className={classes.listitem}>
                        Call Us:
                        <span style={{ fontWeight: "700" }}>1-347-738-6303</span>
                      </Typography>
                      <Box  onClick={() => (window.location = "mailto:info@cryptowater.com ")}>

                      <Typography className={classes.listitem} >
                        {/* 25 W 51st St, New York, NY 10019 */}
                        info@cryptowater.com
                      </Typography>
                      </Box>
                      <Typography
                        variant="h2"
                        style={{ fontSize: "17px" }}
                        className={classes.sociallinknbox}
                      >
                        <span>.</span>
                        &nbsp; Social Links
                      </Typography>
                      <Box pt={2}>
                        <IconButton>
                          <Link
                            target="_blank"
                            href="https://www.facebook.com/"
                          >
                            <FaFacebookF
                              style={{ fontSize: "20px", color: "#fff" }}
                            />
                          </Link>
                        </IconButton>

                        <IconButton>
                          <Link
                            target="_blank"
                            href="https://www.instagram.com/"
                          >
                            <FaInstagram
                              style={{
                                fontSize: "20px",
                                paddingLeft: "10px",
                                color: "#fff",
                              }}
                            />
                          </Link>
                        </IconButton>
                        <IconButton>
                          <Link
                            target="_blank"
                            href="https://twitter.com/i/flow/login"
                          >
                            <FiTwitter
                              style={{
                                fontSize: "20px",
                                paddingLeft: "10px",
                                color: "#fff",
                              }}
                            />
                          </Link>
                        </IconButton>
                        <IconButton>
                          <Link target="_blank" href="https://medium.com/">
                            <AiOutlineMedium
                              style={{
                                fontSize: "20px",
                                paddingLeft: "10px",
                                color: "#fff",
                              }}
                            />
                          </Link>
                        </IconButton>
                        <IconButton>
                          <Link target="_blank" href="https://www.youtube.com/">
                            <FiYoutube
                              style={{
                                fontSize: "20px",
                                paddingLeft: "10px",
                                color: "#fff",
                              }}
                            />
                          </Link>
                        </IconButton>
                      </Box>
                    </List>{" "}
                  </Grid>
                </Grid>
              </Box>
              <Box pt={3} pb={2}>
                <Divider style={{ background: "#02142E" }} />
              </Box>
              <Box className={classes.baseSection}>
                <Box className="imglogo" style={{ width: "80px" }}>
                  <img src="images/waterimage1.png" width="100%" />
                </Box>
                <Box className={classes.privacy}>
                  <RouterLink
                    to="/terms-condition"
                    className={classes.decoration}
                  >
                    <Typography variant="body1">Terms & Conditions</Typography>
                  </RouterLink>
                  &nbsp; &nbsp; &nbsp;
                  <RouterLink
                    to="/privacy-policy"
                    className={classes.decoration}
                  >
                    {" "}
                    <Typography variant="body1"> Privacy Policy</Typography>
                  </RouterLink>{" "}
                  &nbsp; &nbsp; &nbsp;
                  <RouterLink to="/faqs" className={classes.decoration}>
                    {" "}
                    <Typography variant="body1">FAQs</Typography>
                  </RouterLink>{" "}
                  &nbsp; &nbsp; &nbsp;
                  <RouterLink to="/redeem-token" className={classes.decoration}>
                    <Typography variant="body1">Redeem</Typography>
                  </RouterLink>
                  &nbsp; &nbsp; &nbsp;
                  <Box
                    onClick={() => setOpen(true)}
                    className={classes.decoration}
                  >
                    <Typography variant="body1">Cookie Preferences</Typography>
                  </Box>
                </Box>
              </Box>
            </Container>
            <CookieModal setOpen={setOpen} open={open} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

// import {
//   Box,
//   Container,
//   Divider,
//   IconButton,
//   makeStyles,
//   Typography,
// } from "@material-ui/core";
// import React from "react";
// import { FaFacebookF, FaYoutube, FaTwitter, FaInstagram } from "react-icons/fa";

// const useStyle = makeStyles((theme) => ({
//   footerBox: {
//     width: "100%",
//     padding: "50px 0px",
//     zIndex: "4",
//     position: "relative",
//     marginTop: "-250px",
//     backgroundSize: "cover",
//     backgroundImage: "url(images/sliderbanner.png)",
//     backgroundRepeat: "no-repeat",
//     backgroundPosition: "top",
//     [theme.breakpoints.down("xs")]:{
//       marginTop: "-200px",

//     },
//     "& .footerimg":{
//       width:"100%",
//       maxWidth:"200px",
//       [theme.breakpoints.down("xs")]:{
//         maxWidth:"150px",
//       },
//     },
//     "& .iconBox":{
//      fontSize:"20px",
//      background:"#fff",
//      padding:"6px",
//      marginLeft:"20px"

//     },
//     "& .routdisplayBOx":{
//       display:"flex",
//       justifyContent:"center",
//       flexWrap:"wrap",
//       "& p":{
// marginRight:"40px",
// marginTop:"20px"
//       }
//     }
//   },
// }));

// export default function Footer() {
//   const classes = useStyle();

//   return (
//     <Box className={classes.footerBox}>
//       <Container>
//         <Box mt={2} mb={4}>
//           <Divider style={{ background: "#fff" }} />
//         </Box>
//         <Box align="center">
//           <Box>
//             <img src="images/footerimg.png" className="footerimg" />
//           </Box>
//           <Box className="" my={3}>
//             <IconButton  className="iconBox">
//               <FaFacebookF  style={{color:"#1C8AC9"}}/>
//             </IconButton>
//             <IconButton className="iconBox">
//               <FaTwitter   style={{color:"#1C8AC9"}}/>
//             </IconButton>
//             <IconButton className="iconBox">
//               <FaInstagram style={{color:"#000"}}/>
//             </IconButton>
//             <IconButton className="iconBox">
//               <FaYoutube style={{color:"#FE1C1C"}}/>
//             </IconButton>
//           </Box>
//           <Box className="routdisplayBOx">
//             <Typography variant="body2">Developer</Typography>
//             <Typography variant="body2">Company</Typography>
//             <Typography variant="body2">Crypto water</Typography>
//             <Typography variant="body2">Dropcoin</Typography>
//             <Typography variant="body2">Redeem</Typography>
//             <Typography variant="body2">Mine</Typography>
//           </Box>
//         </Box>
//       </Container>
//     </Box>
//   );
// }
