/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import Logo from "src/component/Logo";

import {
  FaTachometerAlt,
  FaUniversity,
  FaWallet,
  FaClipboardCheck,
  FaMoneyBillAlt,
  FaPhoneSquareAlt,
  FaGooglePlusG,
} from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi";
import { RiAdvertisementFill } from "react-icons/ri";
import { IoLogOutOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { GiToken, GiTrade } from "react-icons/gi";
import NavItem from "./NavItem";
import PeopleIcon from "@material-ui/icons/People";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
const sections = [
  {
    items: [
      {
        title: "Home",
        icon: FaTachometerAlt,
        href: "/",
      },
      {
        title: "Dashboard",
        icon: DashboardIcon,
        href: "/dashboard",
      },

      // {
      //   title: "KYC",
      //   icon: FaClipboardCheck,
      //   href: "/kyc",
      // },
      // {
      //   title: "Token",
      //   icon: GiToken,
      //   href: "/token",
      // },
      // {
      //   title: "Currency",
      //   icon: FaMoneyBillAlt,
      //   href: "/currency",
      // },

      // {
      //   title: "Add Devices",
      //   icon: FaGooglePlusG,
      //   href: "/add-devices",
      // },
      {
        title: "User-Management",
        icon: PeopleIcon,
        href: "/users",
      },

      {
        title: "Code Management",
        icon: PeopleIcon,
        href: "/redeem",
      },

      // {
      //   title: "Subscription",
      //   icon: SubscriptionsIcon,
      //   href: "/subscription",
      // },

      // {
      //   title: "Contract Sniffer",
      //   icon: RecentActorsIcon,
      //   href: "/contract-sniffed",
      // },

      // {
      //   title: "Add Admin",
      //   icon: SupervisorAccountIcon,
      //   href: "/add-admin",
      // },
      // {
      //   title: "Add Management",
      //   icon: RiAdvertisementFill,
      //   href: "/Add-management",
      // },

      {
        title: "Static Management",
        icon: HiDocumentText,
        href: "/static-management",
      },

      // {
      //   title: "KYC Pending",
      //   icon: InsertDriveFileIcon,
      //   href: "/kycpending",
      // },
      // {
      //   title: "Device List",
      //   icon: DevicesOtherIcon,
      //   href: "/devicelist",
      // },
    ],
  },
];
const sectionsBelow = [
  {
    items: [
      {
        // title: "Logout",
        icon: ExitToAppIcon,
        href: "/terms-and-condition",
      },
      // {
      //   title: "Privacy Policy",
      //   //icon: PieChartIcon,
      //   href: "/privacy-policy",
      // },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#242538",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    background: "#0E203D",

    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    backdropFilter: "blur(42px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },
  logoutbutton: {
    justifyContent: "space-between",
    paddingLeft: 10,
    borderRadius: 0,
    width: "60px",
    textAlign: "center",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    history.push("/login");
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const logoutHandler = () => {
    window.sessionStorage.removeItem("token");
    window.localStorage.removeItem("tokenname");
    history.push("/login");
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden mdDown>
        <Box
          padding={0}
          className={classes.logoicon}
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/")}
        >
          <Logo
            width="180"
            style={{
              width: "60px",

              cursor: "pointer",
            }}
          />
        </Box>
      </Hidden>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={2}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Box className="side_nev_Bottom">
          {sectionsBelow.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {section.items.map((itemList, i) => {
                return (
                  <Box align="left">
                    <Button
                      fullWidth
                      color="primary"
                      startIcon={
                        <IoLogOutOutline
                          style={{
                            fontSize: "30px",
                            color: "#00C1FF",
                          }}
                        />
                      }
                      key={i}
                      className={classes.logoutbutton}
                      style={{}}
                      onClick={handleClickOpen}
                    >
                      {itemList.title}
                    </Button>

                    <Dialog
                      open={open}
                      fullWidth
                      maxWidth="sm"
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle
                        id="alert-dialog-title"
                        style={{ textAlign: "center" }}
                      >
                        <Box style={{ height: "90px" }}>
                          <img src="images/logout.png" width="20%" alt="" />
                        </Box>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText
                          id="alert-dialog-description"
                          align="center"
                        >
                          Are you sure you wan’t to logout?
                        </DialogContentText>
                        <Box
                          display="flex"
                          justifyContent="center"
                          pb={2}
                          pt={2}
                        >
                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={logoutHandler}
                            >
                              Yes
                            </Button>
                            &nbsp; &nbsp;
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleClose}
                            >
                              No
                            </Button>
                          </Box>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </Box>
                );
              })}

              {/* {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })} */}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
