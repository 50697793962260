import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  ListItem,
  List,
} from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  socialBox: {
    backgroundColor: "#242538",
    "& ul": {
      padding: 0,
      display: "flex",
      alignItems: "center",
      "& li": {
        width: "auto",
        padding: "5px",
        "& a": {
          color: "#fff",
          textDecoration: "none",
          fontSize: "14px",
          fontWeight: 300,
          "&:hover": {
            color: "#5a86ff",
          },
          "& svg": {
            fontSize: "20px",
            [theme.breakpoints.down("xs")]: {
              fontSize: "17px",
            },
          },
        },
      },
    },
    "& .leftBox": {
      justifyContent: "flex-end",
    },
    "& .submit": {
      backgroundColor: "#5a86ff",
      paddingLeft: "5px",
      paddingRight: "5px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
      "&:hover": {
        color: "#fff",
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <Box className={classes.socialBox}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={6} sm={6}>
            <List>
              {/* <ListItem>
                <Link to="">Bitcomin</Link>
              </ListItem>
              <ListItem>
                <Link to="/white-paper">White Paper</Link>
              </ListItem> */}
              <ListItem>
                <Link to="" className="submit">
                  Submit your Token
                </Link>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6} sm={6}>
            <List className="leftBox">
              <ListItem>
                <a href="https://www.instagram.com/" target="_blank">
                  <InstagramIcon />
                </a>
              </ListItem>
              <ListItem>
                <a href="https://twitter.com/" target="_blank">
                  <TwitterIcon />
                </a>
              </ListItem>
              <ListItem>
                <a href="https://www.facebook.com/" target="_blank">
                  <FacebookIcon />
                </a>
              </ListItem>
              <ListItem>
                <a href="https://www.youtube.com/" target="_blank">
                  <YouTubeIcon />
                </a>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
