import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  Avatar,
  MenuList,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Popover,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { AuthContext } from "src/context/Auth";
import { MdKeyboardArrowDown } from "react-icons/md";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// const headersData = [
//   {
//     label: "Home",
//     href: "/",
//   },
//   {
//     label: "Redeem",
//     href: "/redeem-token",
//   },
// ];
const headersData = [
  // {
  //   label: "Developers",
  //   href: "/",
  // },
  // {
  //   label: "Dropcoin",
  //   href: "/",
  // },
  // {
  //   label: "Company",
  //   href: "/",
  // },
  // {
  //   label: "Cryptowater",
  //   href: "/",
  // },
  // {
  //   label: "Redeem",
  //   href: "/redeem-token",
  // },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#E8E8E8",
    height: "41px",
    padding: "0px 17px",
    letterSpacing: "1px",
    marginLeft: "1px",
    marginRight: "1px",
    "&.active": {
      color: "#29B6ED",
      // borderBottom: "2px solid #29B6ED",
    },
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#E8E8E8",
      padding: "10px !important",
      height: "41px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&:active": {
      color: "#0dd0ff",
    },
    "&:hover": {
      color: "#0dd0ff",
    },
  },
  toolbar: {
    display: "flex",
    padding: "0px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#061121",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "@media (max-width: 1220px)": {
      "& .MuiButtonBase-root": {
        // width: "100%",
        // minWidth: "100px",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
      },
    },
  },
  drawericon: {
    // color: "#000",
    // position: "absolute",
    // top: "26px",
    // right: "29px",
    // fontSize: "25px",
    marginLeft: "0px",
  },
  logoImg: {
    width: "200px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "42px",
    },
  },
  menuMobile: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    // borderBottom: "1px solid #234e70",
    padding: "16px",
    "@media (max-width: 1220px)": {
      padding: "13px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  bgbtn: {
    // backgroundColor: "red",
    backgroundImage: "url(./images/btnorange.png)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: " 17px 18px",
    fontSize: " 18px",
    color: " #fff",
    marginLeft: "15px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #EB4D00",
    padding: "0 15px",
    background: "#EB4D00",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "fff",
      color: "#EB4D00",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
  menubox: {
    "& ul": {
      borderRadius: 0,
      background: "#1690f0",
      width: "120px",
      padding: "10px",
      "& a": {
        fontSize: "15px",
      },
    },
  },
  paperbgcolor: {
    backgroundColor: "red",
  },
  muiList: {
    "& .MuiList-padding": {
      marginTop: "50px",
      paddingLeft: "0px",
    },
    "& .MuiMenu-list": {
      background: "#061121",
    },
    "& .MuiMenuItem-root": {
      padding: "8px 20px",
      "&:hover": {
        background: "linear-gradient(180deg, #51D2FC 0%, #0099DC 100%)",
      },
      "& a": {
        color: "#fff !important",
        fontSize: "14px",
        textDecoration: "none",
      },
    },
  },
}));

export default function Header() {
  const auth = useContext(AuthContext);
  console.log("authdataa", auth?.userData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const [anchorEl6, setAnchorEl6] = React.useState(null);
  const [anchorEl7, setAnchorEl7] = React.useState(null);
  const [anchorEl8, setAnchorEl8] = React.useState(null);

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };
  const handleClose5 = () => {
    setAnchorEl5(null);
  };
  const handleClick6 = (event) => {
    setAnchorEl6(event.currentTarget);
  };
  const handleClose6 = () => {
    setAnchorEl6(null);
  };
  const handleClick7 = (event) => {
    setAnchorEl7(event.currentTarget);
  };
  const handleClose7 = () => {
    setAnchorEl7(null);
  };
  const handleClick8 = (event) => {
    setAnchorEl8(event.currentTarget);
  };
  const handleClose8 = () => {
    setAnchorEl8(null);
  };

  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose11 = () => {
    setAnchorEl1(null);
  };

  const open11 = Boolean(anchorEl1);
  const id1 = open11 ? "simple-popover" : undefined;

  const StyledMenu = withStyles({
    paper: {
      marginTop: "10px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const logoutHandler = () => {
    window.sessionStorage.removeItem("token");
    window.localStorage.removeItem("tokenname");
    history.push("/login");
  };
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const {
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    paperbgcolor,
    menubox,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
    bgbtn,
    wallet,
    muiList,
    submenu,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };

  // const handleToggle = (name) => {
  //   setOpen1({ ...open1, [name]: !open1[name] });
  // };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open1);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open1 === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open1;
  // }, [open1]);

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {getMenuButtons()}

            {/* <NavLink
              activeClassName="active"
              {...{
                key: "Developers",
                color: "inherit",
                to: "",
                className: menuButton,
              }}
              onClick={handleClick5}
            >
              Developers
            </NavLink> */}
            {/* start btn  */}

            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/aboutus",
                component: NavLink,
                className: menuButton,
              }}
            >
              About us
            </Button>
            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/our-team",
                component: NavLink,
                className: menuButton,
              }}
            >
              Our Team
            </Button>
            {/* <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/contactUs",
                component: NavLink,
                className: menuButton,
              }}
            >
              Contact us
            </Button> */}
            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/merchandise",
                component: NavLink,
                className: menuButton,
              }}
            >
              Merchandise
            </Button>
            <Button
              aria-describedby={id1}
              {...{
                key: "Developers",
                color: "inherit",
                to: "",
                className: menuButton,
              }}
              onClick={handleClick1}
            >
              Company <ExpandMoreIcon />
            </Button>
            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/redeem-token",
                component: NavLink,
                className: menuButton,
              }}
            >
              Redeem
            </Button>
            {/* <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/",
                component: NavLink,
                className: menuButton,
              }}
            >
              White Paper
            </Button> */}
            {/**
         <Popover
              id1={id1}
              open={open11}
              anchorEl={anchorEl1}
              onClose={handleClose11}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography>The content of the Popover.</Typography>
            </Popover>
        */}
            {/* end */}
            {/**
            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/redeem-token",
                component: NavLink,
                className: menuButton,
              }}
            >
              Redeem
            </Button>
      */}
            {/* {auth.isLogin && ( */}
            {/**
           <Button
              {...{
                key: "dashboard",
                color: "inherit",
                to: "/my-address",
                component: NavLink,
                className: menuButton,
              }}
            >
              Mine
            </Button>
          */}

            {/* )} */}

            {auth?.userData?.role === "ADMIN" && (
              <Button
                {...{
                  key: "dashboard",
                  color: "inherit",
                  to: "/dashboard",
                  component: NavLink,
                  className: menuButton,
                }}
              >
                Dashboard
              </Button>
            )}
            {auth.isLogin && (
              <Button
                {...{
                  key: "dashboard",
                  color: "inherit",
                  to: "/my-wallet",
                  component: NavLink,
                  className: menuButton,
                }}
              >
                Wallet
              </Button>
            )}

            {auth.isLogin && (
              <Button
                {...{
                  key: "My Account",
                  color: "inherit",
                  to: "/my-account",
                  component: NavLink,
                  className: menuButton,
                }}
              >
                My Account
              </Button>
            )}

            {auth.isLogin && auth?.userData?.role == "Admin" && (
              <Button
                {...{
                  key: "Dashboard",
                  color: "inherit",
                  to: "/dashboard",
                  component: NavLink,
                  className: menuButton,
                }}
              >
                Dashboard
              </Button>
            )}

            {!auth.isLogin && (
              <Button
                variant="contained"
                size="large"
                color="primary"
                {...{
                  key: "Dashboard",

                  to: "/login",
                  component: NavLink,
                }}
              >
                Login
              </Button>
            )}

            {auth.isLogin && (
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => setOpen(true)}
                {...{
                  key: "Dashboard",

                  // to: "/login",
                  // component: Link,
                }}
              >
                Logout
              </Button>
            )}
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img src="/images/logo_final.png" width="80px" alt="" />
            {getDrawerChoices()}
            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/aboutus",
                component: NavLink,
                className: menuButton,
              }}
            >
              About us
            </Button>
            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/our-team",
                component: NavLink,
                className: menuButton,
              }}
            >
              Our Team
            </Button>
            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/contactUs",
                component: NavLink,
                className: menuButton,
              }}
            >
              Contact us
            </Button>
            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/merchandise",
                component: NavLink,
                className: menuButton,
              }}
            >
              Merchandise
            </Button>
            <Button
              aria-describedby={id1}
              {...{
                key: "Developers",
                color: "inherit",
                to: "",
                className: menuButton,
              }}
              onClick={handleClick1}
            >
              Company <ExpandMoreIcon />
            </Button>
            {/* <Button
              {...{
                key: "Developers",
                color: "inherit",
                className: menuButton,
              }}
              onClick={handleClick5}
            >
              Developers
            </Button> */}

            {/* <Button
              {...{
                key: "Developers",
                color: "inherit",
                className: menuButton,
              }}
              onClick={handleClick8}
            >
              Dropcoin
            </Button> */}
            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/redeem-token",
                component: NavLink,
                className: menuButton,
              }}
            >
              Redeem
            </Button>
            {auth.isLogin && (
              <Button
                {...{
                  key: "dashboard",
                  color: "inherit",
                  to: "/my-wallet",
                  component: NavLink,
                  className: menuButton,
                }}
              >
                Wallet
              </Button>
            )}
            {auth.isLogin && (
              <Button
                className={menuButton1}
                {...{
                  key: "My Account",
                  color: "inherit",
                  to: "/my-account",
                  component: NavLink,
                }}
              >
                <MenuItem
                  className={menuMobile}
                  style={{
                    borderBottom: "none",
                    marginTop: "-10px",
                    marginLeft: "6px",
                  }}
                >
                  My Account
                </MenuItem>
              </Button>
            )}

            {auth.isLogin && auth?.userData?.role == "Admin" && (
              <Button
                className={menuButton1}
                {...{
                  key: "Dashboard",
                  color: "inherit",
                  to: "/dashboard",
                  component: NavLink,
                }}
              >
                <MenuItem className={menuMobile}>Dashboard</MenuItem>
              </Button>
            )}
            {auth.isLogin && (
              <Button
                className={menuButton1}
                variant="contained"
                size="large"
                style={{
                  marginLeft: "10px",
                  marginTop: "20px",
                  borderBottom: "none",
                }}
                color="primary"
                onClick={() => setOpen(true)}
                {...{
                  key: "Logout",
                  // to: "/login",
                  // component: Link,
                }}
              >
                <MenuItem className={menuMobile}>Log Out</MenuItem>
              </Button>
            )}

            {!auth.isLogin && (
              <Button
                className={menuButton1}
                style={{
                  marginLeft: "10px",
                  marginTop: "20px",
                  borderBottom: "none",
                }}
                variant="contained"
                size="large"
                color="primary"
                {...{
                  key: "Login",

                  to: "/login",
                  component: NavLink,
                }}
              >
                <MenuItem className={menuMobile}>Login</MenuItem>
              </Button>
            )}
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2} align="right">
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#197ab3", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            className={menuButton1}
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: NavLink,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <NavLink to="/">
        <Logo className="logoImg" />
      </NavLink>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: NavLink,
              className: menuButton,
            }}
          >
            {label}
            {/* <ExpandMoreIcon /> */}
          </Button>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "relative"}
        elevation={0}
        style={{
          backgroundColor: "transparent",
          border: "none",
          // paddingBottom: "7px",
          // paddingTop: "12px",
        }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        className={muiList}
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem>
          <NavLink to="/help-planet">Help the planet</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/carrers">Careers</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/where-buy">Where to buy</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/return-bottle">Bottle return</NavLink>
        </MenuItem>

        <MenuItem>
          <NavLink to="/partner-with-us">Partner with us</NavLink>
        </MenuItem>
      </Menu>

  
      <Box>
        <Dialog
          open={open}
          fullWidth
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            <Box style={{ height: "90px" }}>
              <img src="images/logout.png" width="20%" alt="" />
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              align="center"
              style={{
                color: "#fff",
                fontSize: "18px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              <Typography variant="h6">
                Are you sure you wan’t to logout?
              </Typography>
            </DialogContentText>
            <Box display="flex" justifyContent="center" pb={2} pt={2}>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={logoutHandler}
                >
                  Yes
                </Button>
                &nbsp; &nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  No
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}
