import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme)=>({
btn:{
    minWidth: "154px",
    [theme.breakpoints.down("xs")]:{
    minWidth: "100px",
        

    }
}
}))

export default function CookieModal({ open, setOpen }) {
    const classes= useStyles();
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Box onClick={() => setOpen(false)}>
          <IconButton style={{ position: "absolute", top: 10, right: 10 }}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </Box>

        <Box>
          <img src="/images/logo_final.png" />

          <Divider style={{ background: "rgb(2, 20, 46)" }} />
          <Box my={2}>
            <Typography variant="h4" style={{ color: "#fff" }}>
              Privacy Preference Center
            </Typography>
          </Box>
          <Box mb={4}>
            <Typography variant="body1" style={{ color: "#fff" }}>
              When you visit any website, it may store or retrieve information
              on your browser, mostly in the form of cookies. This information
              might be about you, your preferences or your device and is mostly
              used to make the site work as you expect it to. The information
              does not usually directly identify you, but it can give you a more
              personalized web experience. Because we respect your right to
              privacy, you can choose not to allow some types of cookies. Click
              on the different category headings to find out more and change our
              default settings. However, blocking some types of cookies may
              impact your experience of the site and the services we are able to
              offer.
            </Typography>
          </Box>
          <Box mb={4}>
            <Button
              variant="contained"
            
              color="primary"
              className={classes.btn}
              style={{ }}
            >
              Allow All
            </Button>

            <Button
              variant="contained"
              className={classes.btn}
              color="secondary"
              onClick={() => setOpen(false)}
              style={{ marginLeft: "20px" }}
            >
              Deny
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
