import { AuthContext } from "src/context/Auth";
import { Box } from "@material-ui/core";
import React, { useContext } from "react";

const Logo = (props) => {
  const auth = useContext(AuthContext);
  return (
    <Box>
      <img
        src="/images/logo_final.png"
        alt="Logo"
        {...props}
        width="100%"
        style={{ width: "auto", maxWidth: "100%" }}
      />

      {/* <img src="/images/logo.png" alt="Logo" {...props} width="100%"    style={{ maxWidth: '80px', margin: '0 auto' }} /> */}
      {/* <img
        src={
          auth?.userLogo
            ? auth?.userLogo[0]?.logoImage
            : "/images/waterimage1.png"
        }
        alt="Logo"
        {...props}
        width="100%"
        style={{ maxWidth: "100px", margin: "0 auto" }}
      /> */}
    </Box>
  );
};

export default Logo;
