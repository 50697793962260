import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box, Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    overflowX: "auto",

    [theme.breakpoints.down("sm")]: {
      height: "auto",
      overflow: "unset",
    },
  },
  left: {
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      // display:"none",
    },
  },
  box: {
    backgroundColor: "#071A37",
    //boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px',
    [theme.breakpoints.down("sm")]: {
      marginBottom: "50px",
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={5} className={classes.left}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          // backgroundColor="#1690f0"
          className={classes.box}
        >
          <img
            src="images/waterlogo.png"
            style={{
              width: "100%",
              // maxWidth: 270,
              margin: "20px 0",
              cursor: "pointer",
              // display: "none",
            }}
            alt=""
            onClick={() => history.push("/")}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={7}>
        <Box style={{ backgroundColor: "#061121" }}>
          <Container style={{ padding: "0px" }}>
            <Box className={classes.content}>{children}</Box>
          </Container>
        </Box>
      </Grid>
    </Grid>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
