import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/tokens",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/TableTokens/index")),
  },
  {
    exact: true,
    path: "/aboutus",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/Aboutus")),
  },
  {
    exact: true,
    path: "/help-planet",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/HelpPlanet")),
  },
  {
    exact: true,
    path: "/our-team",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/Ourteam")),
  },
  {
    exact: true,
    path: "/full-list",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FullList/index")),
  },
  {
    exact: true,
    path: "/flow-chart",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FlowChart/index")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/dashboard",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/users",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Userlist")),
  },
  {
    exact: true,
    path: "/redeem",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/RedeemList")),
  },

  {
    exact: true,
    path: "/redeem-token",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/RedeemTok/RedeemToken")),
  },

  {
    exact: true,
    path: "/subscription",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Subscription/Subscription")
    ),
  },

  {
    exact: true,
    path: "/subscription-package",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Subscription/Package")
    ),
  },

  {
    exact: true,
    path: "/contract-sniffed",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Contract/ContractSniffer")
    ),
  },
  {
    exact: true,
    path: "/kyc-detail",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },

  {
    exact: true,
    path: "/token-detail",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/User/TokenDetail")),
  },

  {
    exact: true,
    path: "/sniffer-detail",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/User/CoinStake/SnifferDetail")
    ),
  },

  {
    exact: true,
    path: "/user-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/User/UserManagement")
    ),
  },
  {
    exact: true,
    path: "/edit-detail",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/User/EditDetail")),
  },
  {
    exact: true,
    path: "/kyc-pending",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Kycpending/Tokentable1")),
  },
  {
    exact: true,
    path: "/view-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Kycpending/Seeprofile")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/VerifyOtp")),
  },
  {
    exact: true,
    path: "/logo",
    layout: LoginLayout,
    component: lazy(() => import("src/component/Logo")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/signup")),
  },
  {
    exact: true,
    path: "/forget-password",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/coin-approve",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/MyWallet/CoinApprove")),
  },

  {
    exact: true,
    path: "/instrauctions",
    // guard:true,
    component: lazy(() => import("src/views/auth/forget-password-link/index")),
  },
  {
    exact: true,
    path: "/reset-password",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },

  {
    exact: true,
    path: "/white-paper",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/WhitePaper")),
  },
  {
    exact: true,
    path: "/contact-us",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/ContactUs")),
  },
  {
    exact: true,
    path: "/kycpending",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Kycpending")),
  },
  {
    exact: true,
    path: "/add-devices",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Token/Tokentable1")),
  },

  {
    exact: true,
    path: "/devicelist",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Devicelist")),
  },
  {
    exact: true,
    path: "/details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Devicelist/Details")),
  },
  {
    exact: true,
    path: "/token",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Token/Tokentable1")),
  },
  {
    exact: true,
    path: "/kyc",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/KYC/KycList")),
  },

  {
    exact: true,
    path: "/add-admin",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Admin/Index")),
  },

  {
    exact: true,
    path: "/static-management",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/Static")
    ),
  },
  {
    exact: true,
    path: "/banner-list",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BannerList/Index")),
  },
  {
    exact: true,
    path: "/logo-list",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/LogoList/index")),
  },
  {
    exact: true,
    path: "/edit-banner",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BannerList/EditBanner")),
  },
  {
    exact: true,
    path: "/add-statics",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AddStatics")
    ),
  },
  {
    exact: true,
    path: "/add-banner",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BannerList/AddBanner")),
  },
  {
    exact: true,
    path: "/add-logo",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/LogoList/AddLogo")),
  },
  {
    exact: true,
    path: "/edit-banner",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BannerList/Edit-Banner")),
  },

  {
    exact: true,
    path: "/social-links",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/SocialLinks")
    ),
  },

  {
    exact: true,
    path: "/edit-links",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/SocialLinksEdit")
    ),
  },

  {
    exact: true,
    path: "/view-content",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/About")
    ),
  },

  {
    exact: true,
    path: "/view-announcements",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/Announcements")
    ),
  },

  {
    exact: true,
    path: "/edit-announcements",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AnnouncementsEdit")
    ),
  },

  {
    exact: true,
    path: "/edit-content",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AboutEdit")
    ),
  },

  {
    exact: true,
    path: "/view-terms",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/TermsCondition")
    ),
  },
  // {
  //   exact: true,
  //   path: "/view-terms",
  //   // guard:true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/StaticManagement/TermsCondition")
  //   ),
  // },

  {
    exact: true,
    path: "/edit-terms",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/TermsConditionEdit")
    ),
  },
  {
    exact: true,
    path: "/faq",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/Faq")
    ),
  },
  {
    exact: true,
    path: "/edit-faq",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/EditFaq")
    ),
  },

  {
    exact: true,
    path: "/add-faq",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AddFaq")
    ),
  },

  {
    exact: true,
    path: "/policy",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/PrivacyPolicy")
    ),
  },

  {
    exact: true,
    path: "/edit-policy",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/PrivacyPolicyEdit")
    ),
  },

  {
    exact: true,
    path: "/add-token",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Dashboard/KYC")),
  },

  {
    exact: true,
    path: "/add-kyc1",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Dashboard/AddKyc")),
  },
  {
    exact: true,
    path: "/terms-condition",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/TermsAndConditions")),
  },
  {
    exact: true,
    path: "/faqs",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Faq's/Faq's")),
  },
  {
    exact: true,
    path: "/terms-services",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/TermsAndConditions/TermsServices")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Privacy")),
  },
  {
    exact: true,
    path: "/cookies",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Cookies")),
  },
  {
    exact: true,
    path: "/my-address",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Mine/Mineaddress")),
  },
  {
    exact: true,
    path: "/my-account",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyAccount/index")),
  },
  {
    exact: true,
    path: "/my-wallet",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyWallet/index")),
  },
  {
    exact: true,
    path: "/carrers",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/Carrers")),
  },
  {
    exact: true,
    path: "/contactus",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/ContactUs")),
  },
  {
    exact: true,
    path: "/merchandise",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/Merchandize")),
  },
  {
    exact: true,
    path: "/return-bottle",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/ReturnBottle")),
  },
  {
    exact: true,
    path: "/dropcoin",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/DropCoin")),
  },
  {
    exact: true,
    path: "/partner-with-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/PartnerWithUs")),
  },
  {
    exact: true,
    path: "/where-buy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/WhereBuy")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
