// const url = process.env.REACT_APP_BASE_URL;
const url = "http://java-dropcoin.mobiloitte.org:7067"; //Java
// const url = "http://172.16.1.3:7067"; //Java
// const url = "https://java.cryptowater.com"; //Jav
const user = `${url}/account`;
const auth = `${url}/auth`;
const account = `${url}/account`;
const admin = `${url}/account/admin/user-management`;
const wallet = `${url}/wallet`;
const staticData = `${url}/static`;
const dashbaordData = `${url}/account/admin/dashboard`;
const adminnn = `${url}/wallet`;
const ApiConfig = {
  userLogin: `${auth}`,
  userSignUp: `${user}/signup`,
  verifyUser: `${user}/verify-user`,
  myAccount: `${account}/my-account`,
  resendverifyemail: `${account}/resend-verify-email`,
  profileUpdate: `${user}/profile-update`,
  uploadFile: `${user}/upload-file`,
  filteruserdetails: `${admin}/filter-user-details`,
  withdraw: `${wallet}/wallet/withdraw`,
  WithdrawApprove: `${wallet}/wallet/approve-withdraw`,
  AddressWallet: `${wallet}/wallet/get-address`,
  getrandomIddetails: `${wallet}/wallet/get-randomId-details`,
  getalluserbalanceandcoinlist: `${wallet}/wallet/get-all-user-balance-and-coinlist`,
  getdeposits: `${wallet}/wallet/get-deposits`,
  DepositHistoryTransaction: `${wallet}/get-all-transaction-history`,
  randocodegnerate: `${wallet}/wallet/random-id-generate`,
  WithdrawApprove: `${wallet}/wallet/approve-withdraw`,
  addradomIdbalance: `${wallet}/wallet/add-radomId-balance`,
  getStatusdetails: `${wallet}/wallet/get-Status-details`,
  getredeemdetails: `${wallet}/wallet/get-redeem-details`,
  forgotPassword: `${user}/forget-password/mobiloitApp`,
  resetPasswordmobile: `${account}/reset-password-mobile-app`,
  verifyOtpForget: `${user}/verify-sms-code-mobile-app`,
  filteruserdetails: `${admin}/filter-user-details`,
  DeleteUserDetails: `${admin}/delete-user-detail`,
  ViewUserDetails: `${admin}/user-details`,
  dashBoardApi: `${dashbaordData}/totalUserCount`,
  staticContentList: `${staticData}/get-all-static-content-data`,
  faqList: `${staticData}/get-faq-list`,
  addFaq: `${staticData}/add-new-faq`,
  viewFaq: `${staticData}/get-faq-detail`,
  updateFaq: `${staticData}/update-faq`,
  deleteFaq: `${staticData}/delete-faq`,
  viewStaticContent: `${staticData}/get-static-page-data-by-page-key`,
  UpdatestaticContent: `${staticData}/update-static-content-data`,
  activeUser: `${admin}/Activate-Account`,
  BlockAccount: `${admin}/Block-Account`,
  reedemCount: `${wallet}/wallet/get-status-details-by-userid`,
  earnedCount: `${wallet}/wallet/get-Earnd-details`,
  csvfiledownload: `${wallet}/wallet/csv-file-download`,
  getuseraddress: `${wallet}/wallet/get-user-address`,
  addnodeaddress: `${wallet}/wallet/add-node-address`,
  subscribeNow: `${staticData}/post-news-letter-to-user`,
  submitPartner: `${staticData}/submit-partner`,
  adminGetWalletAdd: `${adminnn}/admin/hot-cold-storage/get-storage-details-with-latestTime`,
  adminBalanceUpd: `${adminnn}/admin/hot-cold-storage/update-storage-wallet-balance`,
};

export default ApiConfig;
