export const NetworkContextName = "NETWORK";
// export const ACTIVE_NETWORK = 80001;
// export const couponRegistryContract =
//   "0xF39952C11CF1aaF1E5D90b244B0CF4793CA918A1";

// export const RPC_URL = "https://rpc-mumbai.maticvigil.com/";
// export const blockExplorerUrls = "https://polygonscan.com/";

export const ACTIVE_NETWORK = Number(process.env.REACT_APP_ACTIVE_NETWORK);
export const couponRegistryContract = process.env.REACT_APP_REGISISTRY_CONTRACT;

export const RPC_URL = process.env.REACT_APP_RPC_URL;
export const blockExplorerUrls = process.env.REACT_APP_BLOCKCHAIN_URL;
console.log("env data", Number(process.env.REACT_APP_ACTIVE_NETWORK));

export const ExploreUrl = "http://52.62.227.210:8000/Dropcoin/transaction/";

export const NetworkDetails = [
  {
    chainId: Number(process.env.REACT_APP_ACTIVE_NETWORK),
    chainName: "mumbai - Testnet",
    nativeCurrency: {
      name: "mumbai - Testnet",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: [blockExplorerUrls],
  },
];
