import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import { SUPPORTED_WALLETS } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { calculateTimeLeft } from "src/utils";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    // axios.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    // delete axios.defaults.headers.common.Authorization;
  }
};

const setWalletAddressSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

function checkLogin() {
  const accessToken = sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const { activate, chainId, library, account, deactivate } = useWeb3React();
  const [staticsData, setstaticsData] = useState("");
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState();
  const [userLogo, setUserLogo] = useState();
  const [useBanner, setUserBanner] = useState();
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });
  const getProfileHandler = async () => {
    try {
      console.log("-------MYPROFILE-------");
      const res = await axios({
        method: "GET",
        url: ApiConfig.myAccount,
        headers: {
          authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
        },
      });
      if (res.data.status === 200) {
        setUserData(res.data.data);
        staticContentListHandler();
      }
    } catch (error) {
      console.log("000000", error);
    }
  };
  const getBannerHandler = async () => {
    try {
      const res = await axios.get(ApiConfig.getBanner, {
        // headers: {
        //   token: sessionStorage.getItem("token"),
        // },
      });
      if (res.data.status === 200) {
        setUserBanner(res.data.result);
      } else {
        setIsLogin(false);
      }
    } catch (error) {
      setIsLogin(false);
    }
  };
  const getLogoHandler = async () => {
    try {
      const res = await axios.get(ApiConfig.getLogo, {
        // headers: {
        //   token: sessionStorage.getItem("token"),
        // },
      });
      if (res.data.statusCode === 200) {
        setUserLogo(res.data.result);
      } else {
        setIsLogin(false);
      }
    } catch (error) {
      setIsLogin(false);
    }
  };
  useEffect(() => {
    // getLogoHandler();
    // getBannerHandler();
    // if (isLogin) {
    //   getProfileHandler();
    // }
    if (window.sessionStorage.getItem("token")) {
      getProfileHandler();
    }
  }, [window.sessionStorage.getItem("token")]);

  const staticContentListHandler = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.staticContentList,
        headers: {
          authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
        },
      });
      if (res.data.status === 200) {
        setstaticsData(res.data.data);
      } else {
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  // useEffect(() => {
  //   staticContentListHandler();
  // }, []);

  const connectWalletHandler = (data) => {
    console.log(data, "`123457tdfi");
    try {
      const connector = data.connector;

      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          window.sessionStorage.removeItem("walletName");
          toast.error(JSON.stringify(error.message));
          window.sessionStorage.removeItem("walletName");
          activate(connector);
        }
      });
    } catch (error) {
      toast.error(JSON.stringify(error.message));
    }
  };

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  useEffect(() => {
    if (window.sessionStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.sessionStorage.getItem("walletName")
      );
      connectWalletHandler(selectectWalletDetails[0].data);
    }
  }, []);

  let data = {
    isLogin,
    userData,
    staticsData,
    userLogo,
    timeLeft,
    setEndtime,
    setTimeLeft,
    useBanner,
    getProfileHandler: () => getProfileHandler(),
    updateUser: (account) => {
      setWalletAddressSession(account);
    },
    connectWallet: (data) => connectWalletHandler(data),
    disConnectWallet: (data) => {
      deactivate();
      window.sessionStorage.removeItem("walletName");
    },
    userLogIn: (type, data) => {
      setIsLogin(type);
      setSession(data);
    },
    checkLogin: () => checkLogin(),
    userLogout: (type, data, userData) => {
      setIsLogin(type);
      setSession(data);
      setUserData(userData);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
